const axios = require('axios').default;
import UIkit from 'uikit';

// ==========[ JS HELPERS ]==========
export let docReady = (fn) => {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export let createEvent = (querySelector, event, fn) => {
    let elements = document.querySelectorAll(querySelector);

    if (elements.length > 0) {
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener(event, fn);
        }
    }
}

export let domElementExists = (querySelector) => {
    if (document.querySelectorAll(querySelector).length > 0) {
        return true;
    }

    return false;
}

// ==========[ HELPERS ]==========
export let scrollToElement = (to = "body", behavior = "smooth", block = "start") => {
    document.querySelector(to).scrollIntoView({ behavior: behavior, block: block });
}

// ==========[ FORM HANDLER ]==========
export let formHandler = () => {
    event.preventDefault();

    let formName = event.target.dataset.form;
    let formId = "#" + formName + "-form";
    let loaderId = "#" + formName + "-loader";
    let feedbackId = "#" + formName + "-feedback";
    let formBlockId = "#" + formName + "-form-block";

    if (domElementExists(formId)) {
        let data = new FormData(document.querySelector(formId));
        data.append("js_url", "js_set");

        let remove_loader = true;
        if (domElementExists(loaderId)) {
            document.querySelector(loaderId).classList.remove("d-none");
        }

        axios
            .post(
                ajaxUrl,
                data,
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then(function (response) {
                let responseData = response.data;
                // Redirect to page
                if (responseData.redirect) {
                    window.location.href = responseData.redirect;
                    remove_loader = false;
                }

                // Reload page
                if (responseData.reload) {
                    location.reload();
                    remove_loader = false;
                }

                // Hide loader
                if (remove_loader && domElementExists(loaderId)) {
                    document.querySelector(loaderId).classList.add("d-none");
                }

                // Render HTML
                if (responseData.renderHtml) {
                    renderMultipleHtml(responseData.renderHtml);
                }

                // Hide form
                if (responseData.hideForm) {
                    document.querySelector(formId).classList.add("d-none");
                }

                //Remove previous messages
                if (domElementExists(feedbackId)) {
                    document.querySelector(feedbackId).innerHTML = "";

                    // Add messages to feedbackId
                    if (responseData.messages && responseData.messages.length > 0) {
                        for (var i = 0; i < responseData.messages.length; i++) {
                            let message = document.createElement('div');
                            message.classList.add('uk-alert-' + responseData.messages[i].type);
                            message.setAttribute("uk-alert", "");

                            let link = document.createElement('a');
                            link.classList.add('uk-alert-close');
                            link.setAttribute("uk-close", "");

                            let messageContent = document.createElement('span');
                            messageContent.innerHTML = responseData.messages[i].message;

                            message.appendChild(link);
                            message.appendChild(messageContent);

                            document.querySelector(feedbackId).appendChild(message);
                        }
                        document.querySelector(feedbackId).classList.remove("d-none");
                    }
                }

                // Scroll to formBlockId
                if (responseData.toTop && domElementExists(formBlockId)) {
                    scrollToElement(formBlockId);
                }

                // Remove old error fields
                let errorFields = document.querySelector(formId).querySelectorAll(".uk-form-danger");
                if (errorFields.length > 0) {
                    for (var i = 0, length = errorFields.length; i < length; i++) {
                        errorFields[i].classList.remove("uk-form-danger");
                    }
                }

                // Add danger class to error fields
                if (responseData.errorFields && responseData.errorFields.length > 0) {
                    for (var i = 0; i < responseData.errorFields.length; i++) {
                        if (domElementExists(responseData.errorFields[i])) {
                            document.querySelector(responseData.errorFields[i]).classList.add("uk-form-danger");
                        }
                    }
                }
            });
    }

}

// ==========[ FORM HANDLER ]==========
export let cookieHandler = () => {
    document.querySelector("#cookie-modal").style.display = 'none';

    let cookie_form = document.querySelector("#cookie_form");
    let cookie_data = new FormData(cookie_form);
    cookie_data.append("js_url", "js_set");

    axios
        .post(
            ajaxUrl,
            cookie_data,
            {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }
        ).then(function (response) {
            let responseData = response.data;

            // Render HTML
            if (responseData.addHtml) {
                addHtml('head', responseData.addHtml);
            }
        });
}

// ==========[ DOM MANIPULATORS ]==========
export let renderMultipleHtml = (html) => {
    if (html.length > 0) {
        for (var i = 0; i < html.length; i++) {
            if (domElementExists(html[i].parent)) {
                if (html[i].method == "replace") {
                    replaceHtml(html[i].parent, html[i].html);
                } else if (html[i].method == "add") {
                    addHtml(html[i].parent, html[i].html);
                } else {
                    console.warn("HTML render method not found: " + html[i].method + "; Source: functions.js => renderMultipleHtml");
                }
            } else {
                console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderMultipleHtml");
            }
        }
    }
}

export let replaceHtml = (parent, html) => {
    if (domElementExists(parent)) {
        let parentElement = document.querySelector(parent);
        while (parentElement.firstChild) {
            parentElement.removeChild(parentElement.firstChild);
        }
        parentElement.insertAdjacentHTML("afterbegin", html);
    } else {
        console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderHtml");
    }
}

export let addHtml = (parent, html) => {
    if (domElementExists(parent)) {
        document.querySelector(parent).insertAdjacentHTML("beforeend", html);
    } else {
        console.warn("Dom element not found: " + html[i].parent + "; Source: functions.js => renderHtml");
    }
}